
.containerBusnes{
  display:flex;
  flex-direction:row;
  flex-wrap:nowrap;
  justify-content:center;
  align-items:unset;
  align-content:flex-start;
}

.item-00{
  order:0;
  flex:0 1 auto;
  align-self:auto;
  height:auto;
  width:36%;
  margin-top: 20px;
  margin-left: 10px;

  
}
      
.item-11{
  order:0;
  flex:0 1 auto;
  align-self:auto;
  height:auto;
  margin-top: 5px;
  width:auto;
}
      
.item-22{
  order:0;
  flex:0 1 auto;
  align-self:auto;
  height:auto;
  width:auto;
}

.item-0{
  order:0;
  flex:0 1 auto;
  align-self:auto;
  height:auto;
  width:30%;
  
}
      
.item-1{
  order:0;
  flex:0 1 auto;
  align-self:auto;
  height:auto;
  margin-top: 5px;
  width:60%;
}


.item-0FAC{
  order:0;
  flex:0 1 auto;
  align-self:auto;
  height:auto;
  width:auto;
  
}
      
.item-1FAC{
  order:0;
  flex:0 1 auto;
  align-self:auto;
  height:auto;
  margin-top: 5px;
  width:auto;
}


 .serch{
   width: 30px !important;
 }

     
 .item-10Busnes{
  order:1;
  flex:0 1 auto;
  align-self:auto;
  height:auto;
  width:43%;
  
}

.item-20Busnes{
  order:2;
  flex:0 1 auto;
  align-self:auto;
  height:auto;
  margin-top: 5px;
  width:18%;
  margin-right: 48px;

}

 .item-0Busnes{
  order:0;
  flex:0 1 auto;
  align-self:auto;
  height:auto;
  width:10%;
  margin-top: 5px;
  margin-right: 30px;


}
      
.item-10Busnes{
  order:1;
  flex:0 1 auto;
  align-self:auto;
  height:auto;
  width:43%;
  
}

.item-20Busnes{
  order:2;
  flex:0 1 auto;
  align-self:auto;
  height:auto;
  margin-top: 5px;
  width:18%;
  margin-right: 48px;

}

.contenedorConcept{
width: 80%;
margin: 0 auto; 
}
.RelacionarCenter{
margin: 0 auto;
}

/*CLase que aplica los estilos al elemento a aparecer*/
.show-element {
/*duracion de la animacion, puedes variar el tiempo, entre mas grande mas tarda el efecto*/
animation-duration: 2s;
animation-fill-mode: both;  cursor: pointer;
/*le indicas que animacion quieres aplicarle, animacion hecha por ti en un keyframe*/
animation-name: fadeIn;
background-color: rgb(233, 233, 233);
}

.show-element2 {
  /*duracion de la animacion, puedes variar el tiempo, entre mas grande mas tarda el efecto*/
  animation-duration: 2s;
  animation-fill-mode: both;  cursor: pointer;
  /*le indicas que animacion quieres aplicarle, animacion hecha por ti en un keyframe*/
  padding-left: 61%;
  padding-top: 6px ;
  animation-name: fadeIn;
  position:absolute;
  z-index: 20;
  
  }
  @media (max-width:1380px) {
    .show-element2 {
      animation-duration: 2s;
      animation-fill-mode: both;  cursor: pointer;
      /*le indicas que animacion quieres aplicarle, animacion hecha por ti en un keyframe*/
      padding-left: 65.3%;
      padding-top: 6px ;
      animation-name: fadeIn;
      position:absolute;
      z-index: 20;
      
     }
  
    
  
   }


.contenedorFact{
  width: 90%;
  margin: 0 auto;
}


.divBusnes{
  position: relative;
}
.hide-element {
animation-duration: 2s;
animation-fill-mode: both;
animation-name: fadeOut;
}

/*KeyFrames*/
/*La animacion hecha por ti, puedes ponerle cualquier nombre, solo es para identificarlo*/
@keyframes fadeIn {
0% {
  opacity: 0;
}
100% {
  opacity: 1;
}
}

@keyframes fadeOut {
0% {
  opacity: 1;
}
100% {
  opacity: 0;
}
}

/*Puedes hacer mas animaciones personalizadas con todo lo que pueda hacer css*/
@keyframes otherAnimation {
0% {
  opacity: 0;
  transform: scale(0);
}
100% {
  opacity: 1;
  transform: scale(1);
}
}

@keyframes animationTranslateIn {
from {
  opacity: 0;
  transform: translateX(-200px);
}

to {
  opacity: 1;
  transform: translateX(0px);
}
}




/*Estilos table conceptos*/

.cabezeracolor{
color: white;
font-weight: bold;
font-size: 20px;
}

.unidadConcept{
text-align: center !important;
width: 100px;

}

.descripcionConcept{
width: 800px;
}

.cantidadConcept{
width: 100px;
text-align: center !important;
}

.precioConcept{
width: 150px;
text-align: center;

}

.impuestosConcept{

width: 250px;
}

.subtotalConcept{
width: 150px;

}

.totalConcept{
width: 150px;

}

.accionesConcept{
width: 60px;

}


.totalesCalculados{
background-color: rgb(220, 227, 227) !important;
color: black;
}

.borrarcl{
cursor: pointer;
width: 20px;
}

.tdunidad{
text-align: center;
}
.tddescripcion{
text-align: justify;
}
.tdprecio{
text-align: right;
}

.tdimpuestos{
text-align: center;
}
.tdsubtotal,.tdimpuestos{
text-align:right !important;
}
tbody tr:hover {background-color: rgb(228, 219, 219) ;}


.ContenedorT{
  display:flex;
  flex-direction:row;
  flex-wrap:nowrap;
  justify-content:center;
  align-items:unset;
  align-content:flex-start;
}

.ContenedorT01{
  order:0;
  flex:0 1 auto;
  align-self:auto;
  height:auto;
  width:auto;
  padding-top: 8px;
}
      
.ContenedorT02{
  order:0;
  flex:0 1 auto;
  align-self:auto;
  height:auto;
  width:auto;
}
      
.TypeRelacion{
  float: right;
  padding-top: 40px;
  padding-right: 230px;
}















/*containerFactureResponse-1 fact-1... fact-5 */
.containerFactureResponse {
  width: 90%;
  margin: 0 auto;
}
.fact-1 {
  grid-area: cp1;
}
.fact-2 {
  grid-area: cp2;
}
.fact-3 {
  grid-area: cp3;
}
.fact-4 {
  grid-area: cp4;
}
.fact-5 {
  grid-area: cp5;
}

.containerFactureResponse {
  display: grid;
  grid-template-areas: "cp1 cp2 cp3 cp4 cp5";
  gap: 10px;
}

@media only screen and (max-width: 1301px) {
  .containerFactureResponse {
    grid-template-areas:
      "cp1 cp1 cp2 cp2 cp3"
      "cp4 cp4 cp4 cp5 cp5";
  }
}

@media only screen and (max-width: 700px) {
  .containerFactureResponse {
    grid-template-areas:
      "cp1 cp1 cp1 cp1 cp1"
      "cp2 cp2 cp2 cp3 cp3"
      "cp4 cp4 cp4 cp4 cp4"
      "cp5 cp5 cp5 cp5 cp5";
  }
}

/*containerFactureResponse-2 fact-6... fact-8 */
.containerSupp {
  width: 80%;
  padding-right: 1%;
  margin: 0 auto;
}

.fact-6 {
  grid-area: cp6;
  margin-top: 15px;
  padding-left: 1%;
}
.fact-7 {
  grid-area: cp7;
}
.fact-8 {
  grid-area: cp8;
}

.containerFactureResponse-2 {
  display: grid;
  grid-template-areas: "cp6 cp6  cp6 cp6  cp6 cp6 cp7 cp8";
  gap: 10px;
  width: 89%;
  margin: 0 auto;
}

.fact6-reactSearchautocomplete {
  width: 100%;
}

@media only screen and (max-width: 1201px) {
  .containerFactureResponse-2 {
    grid-template-areas:
      "cp6 cp6 cp6 cp6 cp6 cp6 cp6 cp6"
      "cp7 cp7 cp7 cp8 cp8 cp8 cp8 cp8";
  }

  .fact6-reactSearchautocomplete {
    width: 100%;
    margin-left: 1%;
  }
}

@media only screen and (max-width: 900px) {
  .containerFactureResponse-2 {
    grid-template-areas:
      "cp6 cp6 cp6 cp6 cp6 cp6 cp6 cp6"
      "cp7 cp7 cp7 cp8 cp8 cp8 cp8 cp8";
  }

  .fact6-reactSearchautocomplete {
    width: 100%;
    margin-left: 1.5%;
  }
}

@media only screen and (max-width: 700px) {
  .containerFactureResponse-2 {
    grid-template-areas:
      "cp6 cp6 cp6 cp6 cp6 cp6 cp6 cp6"
      "cp7 cp7 cp7 cp7 cp7 cp7 cp7 cp7"
      "cp8 cp8 cp8 cp8 cp8 cp8 cp8 cp8";
  }
}

/*containerFactureResponse-3 fact-9... fact-10 */
.containerFactureResponse-3 {
  width: 90%;
  margin: 0 auto;
}
.fact-9 {
  grid-area: cp9;
  margin-top: 15px;
  padding-left: 1%;

}
.fact-10 {
  grid-area: cp10;
  margin-left: 3%;
}

.textareaContenidoDescription {
  width: 100%;
  margin-top: 15px;
  max-height: 300px;
  height: 100px !important;
}

.containerFactureResponse-3 {
  display: grid;
  grid-template-areas: "cp9 cp9 cp9 cp9 cp9 cp10 cp10 cp10";
  gap: 10px;
}


@media only screen and (max-width: 1440) {
    .fact-9 {
      
        margin-left: 3%;
      }
      .fact-10 {
        margin-left: 3%;
      }
  }


@media only screen and (max-width: 900px) {
  .fact-9 {
    margin-left: 1%;
  }
  .containerFactureResponse-3 {
    grid-template-areas:
      "cp9 cp9 cp9 cp9 cp9 cp9 cp9 cp9"
      "cp10 cp10 cp10 cp10 cp10 cp10 cp10 cp10";
  }
}

/*containerFactureResponse-4 fact-11... fact-15 */
.containerFactureResponse-4 {
  width: 90%;
  margin: 0 auto;
  display: grid;
  gap: 10px;
}
.fact-11 {
  grid-area: cp11;
}
.fact-12 {
  grid-area: cp12;
}
.fact-13 {
  grid-area: cp13;
}
.fact-14 {
  grid-area: cp14;
}
.fact-15 {
  grid-area: cp15;
}

.containerFactureResponse-4 {
  display: grid;
  grid-template-areas: "cp11 cp12 cp13 cp14 cp15 cp15";
  gap: 10px;
}

@media only screen and (max-width: 1201px) {
  .containerFactureResponse-4 {
    grid-template-areas:
      "cp11 cp11 cp12 cp12 cp13 cp13"
      "cp14 cp14 cp15 cp15 cp15 cp15";
  }
}

@media only screen and (max-width: 700px) {
  .containerFactureResponse-4 {
    grid-template-areas:
      "cp11 cp11 cp11 cp12 cp12 cp12"
      "cp13 cp14 cp14 cp14 cp14 cp14"
      "cp15 cp15 cp15 cp15 cp15 cp15";
  }
}

/*containerFactureResponse-5 fact-16 y 17 */
.containerFactureResponse-5 {
  width: 90%;
  margin: 0 auto;
  display: grid;
  gap: 10px;
}
.fact-16 {
  grid-area: cp16;
}
.fact-17 {
  grid-area: cp17;
}

.containerFactureResponse-5 {
  display: grid;
  grid-template-areas:
    "cp16"
    "cp17";
  gap: 10px;
}



/*containerFactureResponse-0 fact-18... fact-20 */
.containerFactureResponse-0{
  width: 90%;
  margin: 0 auto;
  display: grid;
  gap: 10px;
}
.fact-18 {
  grid-area: cp18;
}
.fact-19 {
  grid-area: cp19;
}
.fact-20 {
  grid-area: cp20;
}

.containerFactureResponse-0 {
  display: grid;
  grid-template-areas: 
  "cp18 cp18 cp18 cp18 cp18 cp18"
  "cp19 cp19 cp19 cp20 cp20 cp20";
  gap: 10px;
}

.containerpayment {
  width: 80%;
  margin: 0 0;
  display: grid;
  grid-template-areas: 
  "cp19 cp19 cp19 cp20 cp20 cp20";
  gap: 10px;
}



/*containerFactureResponse-0 fact-18... fact-20 */
.containerFactureResponse-Show-element{
  width: 90%;
  margin: 0 auto;
  display: grid;
  gap: 10px;
}
.colorFact-21{background-color: green !important;}
.colorFact-22{background-color: red !important;}
.fact-21 {
  grid-area: cp21;
}
.fact-22 {
  grid-area: cp22;
}
.fact-23 {
  grid-area: cp23;
}
.fact-24 {
  grid-area: cp24;
}
.containerFactureResponse-Show-element {
  display: grid;
  grid-template-areas: 
  "cp21 cp21 cp21 cp22 cp22 cp22"
  "cp23 cp23 cp23 cp23 cp23 cp23"
  "cp24 cp24 cp24 cp24 cp24 cp24";
  gap: 10px;
}




/*Estilos table conceptos*/

.contenedorConcept {
    width: 90%;
    margin: 0 auto;
    overflow-x: auto;
  }

.cabezeracolor{
    color: white;
    font-weight: bold;
    font-size: 20px;
    }
    
    .unidadConcept{
    text-align: center;
    width: 100px;
    }
    
    .descripcionConcept{
    width: 800px;
    }
    
    .cantidadConcept{
    width: 100px;
    }
    
    .precioConcept{
    width: 150px;
    text-align: center;
    
    }
    
    .impuestosConcept{
    
    width: 250px;
    }
    
    .subtotalConcept{
    width: 150px;
    
    }
    
    .totalConcept{
    width: 150px;
    
    }
    
    .accionesConcept{
    width: 60px;
    
    }
    
    
    .totalesCalculados{
    background-color: rgb(220, 227, 227) !important;
    color: black;
    }
    
    .borrarcl{
    cursor: pointer;
    width: 20px;
    }
    
    .tdunidad{
    text-align: center;
    }
    .tddescripcion{
    text-align: justify;
    }
    .tdprecio{
    text-align: right;
    }
    
    .tdimpuestos{
    text-align: center;
    }
    .tdsubtotal,.tdimpuestos{
    text-align:right !important;
    }
    tbody tr:hover {background-color: rgb(228, 219, 219) ;}


    @media only screen and (max-width: 890px) {
        .contenedorConcept {
            width: 90%;
            margin: 0 auto;
            overflow-x: auto;

          }

        .contenedorConcept tbody {
            font-size: 14px;
        }
        .borrarcolum{
            width: 20px;
            height: 20px;
        }



        .cabezeracolor{
            color: white;
            font-weight: bold;
            font-size: 14px;
            margin: 10px;
            border-collapse: separate;

            }

      }


/*CSS table relación*/

.tableRelacion{
  width: 100%;
  overflow-x: auto;
  white-space: normal !important;

}

.tableRelacion td:nth-child(5){
  text-align: right;
}


.tableRelacion thead th:nth-child(5){
  width: 15%;
}

.tableRelacion thead td:nth-child(4){
  text-align: justify !important;
}


.tableRelacion td:nth-child(5){
  text-align: right;
}


.tableRelacion thead th:nth-child(5){
  width: 15%;
}

.tableRelacion thead td:nth-child(4){
  text-align: justify !important;
}


