.footer-table-container{
    float: left;
    padding-top: 10px;
    font-size: 25px;
}
.exportartablecontainer{
    z-index: 150;
    position: relative;
}
.exportartablecontainerpdf{
    z-index: 150;
    position: relative;
    width: 30px;
}
.icono-gs{
    z-index: 150;
    position: relative;
}
.footer-table-container{
    font-size: 25px !important;
}