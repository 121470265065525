.LefCheckbox {
  float: left;
  width: 20px;
}

.edipa {
  width: 100px;
}

.TContent {
  font-size: 14px !important;
  margin: 0 auto;
}

.TContent td:nth-child(1) {
  text-align: center !important;
}

.TContent td:nth-child(4) {
  text-align: justify !important;
}

.TContent td:nth-child(9) {
  text-align: center;
}

.TContent td:nth-child(8) {
  text-align: center;
}

.TContent td:nth-child(9) {
  text-align: center;
}

.campoeditinput {
  width: 90px;
}

.inputEditDate {
  width: 100px;
}

.checkboxslecr {
  margin-left: 25px;
}

#custom-select {
  font-size: 14px;
}

.pagoDIferencia {
  width: 300px;
}

.contenedorPagosSelect {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
}
.campoPago1 {
  grid-area: 1 / 1 / 2 / 2;
}
.campoPago2 {
  grid-area: 1 / 2 / 2 / 3;
  margin-left: 10px;
}
.campoPagoSelect {
  grid-area: 1 / 3 / 2 / 4;
}

.contenedorPagosSelect h4 {
  color: white;
  font-size: 14px;
  font-weight: bold;
}

#fechaHoraPago2 {
  width: 14% !important;
  margin-top: 10px;
  height: 39px;
  text-align: center;
  margin-right: 11px;
  margin-left: 5px;
}

.item-00Pago {
  order: 0;
  flex: 0 1 auto;
  align-self: auto;
  height: auto;
  width: 35%;
}

.item-00Banco {
  order: 0;
  flex: 0 1 auto;
  align-self: auto;
  height: auto;
  width: 20%;
  padding-left: 38px;
}

.item-11Banco {
  order: 0;
  flex: 0 1 auto;
  align-self: auto;
  height: auto;
  margin-top: 5px;
  width: 35%;
}

.item-11Pago {
  order: 0;
  flex: 0 1 auto;
  align-self: auto;
  height: auto;
  margin-top: 5px;
  width: 13.5%;
  margin-right: 11px;
}

.datosExtra {
  padding-right: 27.5%;
}

.item-00BancoB {
  order: 0;
  flex: 0 1 auto;
  align-self: auto;
  height: auto;
  width: 27%;
}

.item-11BancoB {
  order: 0;
  flex: 0 1 auto;
  align-self: auto;
  height: auto;
  margin-top: 5px;
  width: 22%;
  margin-right: 20px;
}

/* RESPONSE TABLA */
@media only screen and (max-width: 1920px) {
  .TContent {
    width: 98%;
    font-size: 12.8px;
  }
  .cancel {
    cursor: pointer;
    width: 38%;
    height: 38%;
  }
  #custom-select,
  #custom-filter {
    font-size: 12.8px !important;
  }
}

@media only screen and (max-width: 1680px) {
  .TContent {
    font-size: 10.6px;
    width: 98%;
  }
  #custom-select,
  #custom-filter {
    font-size: 10.6px !important;
  }
}

@media only screen and (max-width: 1600px) {
  .TableConsultarTodo {
    font-size: 10px;
    width: 98%;
  }
  #custom-select,
  #custom-filter {
    font-size: 10 !important;
  }
}

@media only screen and (max-width: 1440px) {
  .TContent {
    font-size: 8.6px;
    width: 98.5%;
  }
  .cancel {
    cursor: pointer;
    width: 48%;
    height: 48%;
  }
  #custom-select,
  #custom-filter {
    font-size: 8.5px;
  }
}

@media only screen and (max-width: 1368px) {
  .TContent {
    font-size: 8.5px;
    width: 98.5%;
  }
  #custom-select,
  #custom-filter {
    font-size: 8.5px !important;
  }
  #custom-select {
    width: 68px;
  }
}

@media only screen and (max-width: 1280px) {
  .TContent {
    font-size: 7.8px;
    width: 98%;
  }

  #custom-select,
  #custom-filter {
    font-size: 7.8 !important;
  }
  #custom-select {
    width: 62px;
  }

  .cancel {
    cursor: pointer;
    width: 48%;
    height: 48%;
  }

  .button {
    font-size: 8px;
    padding: 2px;
    width: 80px;
    margin: 4px;
  }
}

@media only screen and (max-width: 1152px) {
  .TContent {
    font-size: 7px;
    width: 94%;
    margin: 0 0;
    padding-left: 10px;
  }

  #custom-select,
  #custom-filter {
    font-size: 7 !important;
  }
  #custom-select {
    width: 58px;
  }

  .cancel {
    cursor: pointer;
    width: 48%;
    height: 48%;
  }

  .button {
    font-size: 8px;
    padding: 2px;
    width: 80px;
    margin: 4px;
  }
}

@media only screen and (max-width: 1024px) {
  .TableConsultarTodo {
    font-size: 7px;
    width: 83%;
    margin: 0 0;
    padding-left: 10px;
  }

  #custom-select,
  #custom-filter {
    font-size: 7 !important;
  }
  #custom-select {
    width: 58px;
  }

  .cancel {
    cursor: pointer;
    width: 48%;
    height: 48%;
  }

  .button {
    font-size: 8px;
    padding: 2px;
    width: 80px;
    margin: 4px;
  }
}

@media only screen and (max-width: 830px) {
  .TableConsultarTodo {
    font-size: 7px;
    max-width: 66%;
    margin: 0 0;
    padding-left: 10px;
  }

  #custom-select,
  #custom-filter {
    font-size: 7 !important;
  }
  #custom-select {
    width: 58px;
  }

  .cancel {
    cursor: pointer;
    width: 48%;
    height: 48%;
  }

  .button {
    font-size: 8px;
    padding: 2px;
    width: 80px;
    margin: 4px;
  }
}

.abajoP {
  z-index: 2;
}

.TCDPT tr td{
  background-color: transparent !important;
  border-collapse:collapse
}

.TCDPT td{
  background-color: transparent !important;
  margin-bottom: 10px;
  width: 50px;
}


.NoInput{
  width: 13px!important;
} 

.td1{
  border: none;
  border-radius: none;
  border-right-width: 10px;
}

.pagoColor{
  width: 53px;
  color: white;
  height: 20px;
  font-size: 10px;
}

.relacionarPago{
  width: 30px;
}